<template>
  <div class="w-full">
    <div class="px-4 pt-4 md:px-6 md:pt-6">
      <!-- Modal Header -->
      <div
        class="flex flex-col items-center justify-start pb-4 border-b border-neutral-600 md:pb-6 sm:flex-row"
      >
        <div
          class="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-purple-600 rounded-full ring-2 ring-transparent ring-purple-400"
        >
          <fa-icon icon="bars-staggered" class="text-purple-100" />
        </div>
        <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            class="text-lg font-medium leading-6 truncate text-neutral-100"
            id="modal-title"
          >
            Create New Previz
          </h3>
          <div class="mt-2 truncate">
            <p class="text-sm text-neutral-400">
              Create a new Previz in this drive
            </p>
          </div>
        </div>
      </div>

      <!-- Modal Content -->
      <form
        class="my-4 space-y-4 divide-y divide-neutral-600 md:space-y-6 md:my-6"
        @submit.prevent="checkForm"
      >
        <div>
          <label
            for="previzName"
            class="block text-sm font-medium text-neutral-400"
            >Previz Name</label
          >
          <div class="relative mt-1 rounded-md shadow-inner">
            <p
              class="mt-2 text-sm text-red-600"
              id="title-error"
              v-show="error"
            >
              {{ error }}
            </p>

            <input
              tabindex="0"
              type="text"
              name="title"
              id="previzName"
              placeholder="Enter name for new Previz"
              maxLength="255"
              v-model="form.name"
              autofocus
              ref="nameinput"
              @keydown.enter.exact.prevent="submit"
              class="block w-full bg-black rounded-md shadow-inner placeholder-neutral-400 focus:placeholder-neutral-100 text-neutral-400 focus:text-neutral-100 focus:bg-neutral-900 border-neutral-600 focus-within:border-transparent focus-within:ring-2 focus-within:ring-indigo-600"
            />
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Footer -->
    <div
      class="flex flex-row w-full p-4 space-x-4 border-t border-transparent bg-neutral-800 border-neutral-600 md:space-x-reverse md:flex-row-reverse md:justify-start"
    >
      <template v-if="form.busy">
        <button
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm cursor-wait shrink-0 md:flex-auto md:flex-grow-0 text-neutral-100 ring-offset-0 bg-neutral-600 border-neutral-400"
          type="button"
        >
          <fa-icon
            icon="spinner"
            spin
            class="my-auto mr-1 text-neutral-100"
          />Working
        </button>
      </template>
      <template v-else>
        <button
          type="button"
          :class="{ loading: form.busy }"
          @click.prevent="submit"
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium bg-blue-700 border border-transparent rounded-md shadow-sm shrink-0 md:flex-auto md:flex-grow-0 text-neutral-100 ring-offset-0 hover:bg-blue-600 focus:ring-1 focus:border-blue-600 focus:ring-blue-400"
        >
          Create
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium bg-transparent border rounded-md shadow-sm text-neutral-400 hover:text-neutral-100 hover:border-neutral-400 border-neutral-600 hover:bg-neutral-600 shrink-0 md:flex-auto md:flex-grow-0 "
        >
          Cancel
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'previz-create-modal',

  props: ['parent', 'project'],

  data() {
    return {
      form: new SparkForm({
        name: null
      }),
      error: null
      // formFormHasErrors: false
    }
  },

  mounted() {
    this.$refs.nameinput.focus()
  },

  methods: {
    submit() {
      if (this.readyToSubmit) {
        this.form.startProcessing()
        this.$store
          .dispatch('assets/newPreviz', {
            project: this.project,
            parent: this.parent,
            name: this.form.name
          })
          .then((result) => {
            this.form.finishProcessing()
            this.$emit('close')
            this.$store
              .dispatch('assets/loadPrevizInDrive', {
                project: this.project
              })
              .then(
                this.$router.push({
                  name: 'editor',
                  params: {
                    uuid: result.id
                  },
                  query: {
                    drive: this.project.id
                  }
                })
              )
          })
          .catch((response) => {
            this.error = response.errors[0].detail
            this.form.finishProcessing()
          })
      }
    },

    checkForm() {
      this.error = null
      if (this.$refs.input.value.trim().length === 0) {
        this.error = 'Name cannot be blank'
      }
      this.submit()
    }
  },

  computed: {
    readyToSubmit() {
      return !this.error
    },
    user() {
      return this.$store.getters.user
    }
  },
  watch: {
    'form.name'() {
      this.error = null
    }
  }
}
</script>
